import React, { useMemo, useRef, useState } from 'react';
import { SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { InnerPage } from './InnerPage';
import { MenuListItem } from '../components/nav/menu';
import { PATHS } from '../paths';
import { ServiceProviderInnerPage } from './ServiceProviderInnerPage';
import { useLoginAs } from './useLoginAs';
import { Permissions } from '../api/permissions/permissions';
import { SpSidebarProvider } from '../components/organization/fm/inviteServiceProviders/sidebar/SpSidebarProvider';
import { TooltipIconButton } from '../components/buttons/TooltipIconButton';

export const ServiceProviderPage: React.FC<{
  children?: React.ReactNode;
  noTopLevelScroll?: boolean;
}> = ({ children, noTopLevelScroll }) => {
  const { loginAs } = useLoginAs();
  const [invitesCount, setInvitesCount] = useState(0);

  const sidebarCtxValue = useRef<any>(null);
  const primaryNav: MenuListItem[] = useMemo(() => [
    {
      id: 'sp-bid-center',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="megaphone" />
        </SvgIcon>
      ),
      text: 'Bid Center',
      url: PATHS.sp.bundles,
      badge: invitesCount > 0 ? {
        number: invitesCount,
        label: `${invitesCount} bid invites`,
      } : undefined,
    },
    {
      id: 'sp-chat',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="comment-alt-lines" />
        </SvgIcon>
      ),
      text: 'Messages',
      url: PATHS.sp.chat,
      disabled: loginAs !== null,
      title: loginAs !== null
        ? 'You cannot message as yourself from another organization'
        : undefined,
      permission: Permissions.SEND_MESSAGE,
    },
  ], [invitesCount, loginAs]);
  const secondaryNav: MenuListItem[] = useMemo(() => ([
    {
      id: 'sp-profile',
      text: 'Profile',
      title: 'Profile',
      type: '_menu_item_text' as const,
      iconRight: (
        <TooltipIconButton
          title="View your profile"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            sidebarCtxValue.current?.openYourOwnSidebar();
          }}
        >
          <SvgIcon fontSize="small">
            <FontAwesomeIcon icon="eye" fontSize={14} />
          </SvgIcon>
        </TooltipIconButton>
      ),
    },
    {
      id: 'sp-profile-info',
      text: 'Company Info',
      title: 'Company Info',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="building-magnifying-glass" />
        </SvgIcon>
      ),
      type: '_menu_item_url' as const,
      url: PATHS.sp.profile,
    },
    {
      id: 'sp-services',
      text: 'Services',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="screwdriver-wrench" />
        </SvgIcon>
      ),
      title: 'Services',
      type: '_menu_item_url' as const,
      url: PATHS.sp.services,
    },
    {
      id: 'sp-diversity',
      text: 'Diversity',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="person-circle-question" />
        </SvgIcon>
      ),
      title: 'Diversity & Classification',
      type: '_menu_item_url' as const,
      url: PATHS.sp.diversity,
    },
    {
      id: 'sp-documents',
      text: 'Documents',
      title: 'Documents',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="file-alt" />
        </SvgIcon>
      ),
      type: '_menu_item_url' as const,
      url: PATHS.sp.documents,
    },
    {
      id: 'sp-team',
      type: '_menu_item_url',
      url: PATHS.sp.team,
      text: 'Team',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon={'users' as IconProp} />
        </SvgIcon>
      ),
    },
    {
      id: 'sp-billing',
      type: '_menu_item_url',
      url: PATHS.sp.billing,
      text: 'Billing',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon={'dollar-sign' as IconProp} />
        </SvgIcon>
      ),
      permission: Permissions.ORGANIZATION_OWNER,
    },
  ]), []);

  return (
    <InnerPage
      navType="sp"
      primaryNav={primaryNav}
      secondaryNav={secondaryNav}
      noTopLevelScroll={noTopLevelScroll}
    >
      <SpSidebarProvider navType="sp" ref={sidebarCtxValue}>
        <ServiceProviderInnerPage setInvitesCount={setInvitesCount}>
          {children}
        </ServiceProviderInnerPage>
      </SpSidebarProvider>
    </InnerPage>
  );
};
