import React, { useEffect } from 'react';
import {
  CustomMessageActions,
  DefaultStreamChatGenerics,
  MessageActionsArray,
  MessageList as DefaultMessageList,
  MessageListProps,
  useChannelActionContext,
} from 'stream-chat-react';
import type { ComponentContextValue } from 'stream-chat-react/dist/context/ComponentContext';
import { openNewTicketForm } from '../../common/freshchat/openNewTicketForm';
import { Message } from '../Message/Message';
import { useChatContext } from '../ChatContext';

const msgCustomActions: CustomMessageActions = {
  'Create Procursys Support Ticket': openNewTicketForm,
};

const messageActions: MessageActionsArray = ['react'];

export const MessageList: React.FC<MessageListProps> = (props) => {
  const { botMessagesHidden, channelJumpTarget: { msgId, setMsgId } } = useChatContext();
  const { jumpToMessage } = useChannelActionContext();

  useEffect(() => {
    if (msgId) {
      setMsgId(null);
      jumpToMessage(msgId);
    }
    // eslint-disable-next-line
  }, [jumpToMessage, msgId]);

  return (
    <DefaultMessageList
      disableDateSeparator={botMessagesHidden}
      customMessageActions={msgCustomActions}
      messageActions={messageActions}
      Message={Message as ComponentContextValue<DefaultStreamChatGenerics>['Message']}
      {...props}
    />
  );
};
