import { library } from '@fortawesome/fontawesome-svg-core';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faSnowflakes } from '@fortawesome/pro-solid-svg-icons/faSnowflakes';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons/faMegaphone';
import { faMessageLines } from '@fortawesome/pro-solid-svg-icons/faMessageLines';
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons/faFileSignature';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
import { faBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase';
import { faBoxArchive } from '@fortawesome/pro-solid-svg-icons/faBoxArchive';
import { faGaugeHigh } from '@fortawesome/pro-solid-svg-icons/faGaugeHigh';
import { faHeadset } from '@fortawesome/pro-solid-svg-icons/faHeadset';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faUserGear } from '@fortawesome/pro-solid-svg-icons/faUserGear';
import { faArrowRightArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowRightArrowLeft';
import { faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons/faArrowRightFromBracket';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faTrees } from '@fortawesome/pro-solid-svg-icons/faTrees';
import { faOutlet } from '@fortawesome/pro-solid-svg-icons/faOutlet';
import { faSprinkler } from '@fortawesome/pro-solid-svg-icons/faSprinkler';
import { faParkingCircle } from '@fortawesome/pro-solid-svg-icons/faParkingCircle';
import { faFaucetDrip } from '@fortawesome/pro-solid-svg-icons/faFaucetDrip';
import { faAirConditioner } from '@fortawesome/pro-solid-svg-icons/faAirConditioner';
import { faBug } from '@fortawesome/pro-solid-svg-icons/faBug';
import { faVacuum } from '@fortawesome/pro-solid-svg-icons/faVacuum';
import { faHammer } from '@fortawesome/pro-solid-svg-icons/faHammer';
import { faShieldAlt } from '@fortawesome/pro-solid-svg-icons/faShieldAlt';
import { faTape } from '@fortawesome/pro-solid-svg-icons/faTape';
import { faGalaxy } from '@fortawesome/pro-solid-svg-icons/faGalaxy';
import { faWindowFrame } from '@fortawesome/pro-solid-svg-icons/faWindowFrame';
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons/faShoppingCart';
import { faPersonDollyEmpty } from '@fortawesome/pro-solid-svg-icons/faPersonDollyEmpty';
import { faPaintbrush } from '@fortawesome/pro-solid-svg-icons/faPaintbrush';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import { faBallPile } from '@fortawesome/pro-solid-svg-icons/faBallPile';
import { faFolderArrowUp } from '@fortawesome/pro-solid-svg-icons/faFolderArrowUp';
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook';
import { faGhost } from '@fortawesome/pro-solid-svg-icons/faGhost';
import { faFlask } from '@fortawesome/pro-solid-svg-icons/faFlask';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo';
import { faInboxIn } from '@fortawesome/pro-solid-svg-icons/faInboxIn';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import { faThumbsDown } from '@fortawesome/pro-solid-svg-icons/faThumbsDown';
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faCalendarExclamation } from '@fortawesome/pro-solid-svg-icons/faCalendarExclamation';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faArrowFromRight } from '@fortawesome/pro-solid-svg-icons/faArrowFromRight';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faHandshake } from '@fortawesome/pro-solid-svg-icons/faHandshake';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons/faShieldCheck';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { faSignature } from '@fortawesome/pro-solid-svg-icons/faSignature';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faFileLines } from '@fortawesome/pro-solid-svg-icons/faFileLines';
import { faTableCellsLarge } from '@fortawesome/pro-solid-svg-icons/faTableCellsLarge';
import { faCrown } from '@fortawesome/pro-solid-svg-icons/faCrown';
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { faSortAlt } from '@fortawesome/pro-solid-svg-icons/faSortAlt';
import { faArrowToRight } from '@fortawesome/pro-solid-svg-icons/faArrowToRight';
import { faStickyNote } from '@fortawesome/pro-solid-svg-icons/faStickyNote';
import { faBuildingMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faBuildingMagnifyingGlass';
import { faPersonCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faPersonCircleQuestion';
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons/faScrewdriverWrench';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory';
import { faFileInvoice } from '@fortawesome/pro-solid-svg-icons/faFileInvoice';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faHourglassStart } from '@fortawesome/pro-solid-svg-icons/faHourglassStart';
import { faLockOpenAlt } from '@fortawesome/pro-solid-svg-icons/faLockOpenAlt';
import { faHatSanta } from '@fortawesome/pro-solid-svg-icons/faHatSanta';
import { faArrowFromTop } from '@fortawesome/pro-solid-svg-icons/faArrowFromTop';
import { faArrowToTop } from '@fortawesome/pro-solid-svg-icons/faArrowToTop';
import { faPause } from '@fortawesome/pro-solid-svg-icons/faPause';

library.add(
  faMinus,
  faPlus,
  faLocationDot,
  faBuilding,
  faMegaphone,
  faMessageLines,
  faFileSignature,
  faFilter,
  faChartBar,
  faBriefcase,
  faBoxArchive,
  faGaugeHigh,
  faHeadset,
  faBell,
  faUserGear,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faUser,
  faAddressBook,
  faInfo,
  faUsers,
  faFlask,
  faUserPlus,
  faBallPile,
  faGhost,
  faFolderArrowUp,
  faInboxIn,
  faThumbsUp,
  faThumbsDown,
  faShare,
  faCalendarExclamation,
  faPen,
  faPause,
  faArrowFromRight,
  faArrowFromTop,
  faArrowToTop,
  faClock,
  faCheck,
  faHandshake,
  faShieldCheck,
  faStar,
  faDollarSign,
  faSignature,
  faTimes,
  faPaperPlane,
  faEnvelope,
  faDownload,
  faFileLines,
  faTableCellsLarge,
  faCrown,
  faAddressCard,
  faEllipsisH,
  faBan,
  faArrowUpRightFromSquare,
  faLockAlt,
  faSortAlt,
  faArrowToRight,
  faStickyNote,
  faBuildingMagnifyingGlass,
  faPersonCircleQuestion,
  faScrewdriverWrench,
  faEye,
  faCopy,
  faChevronDown,
  faHistory,
  faFileInvoice,
  faExclamationTriangle,
  faHourglassStart,
  faLockOpenAlt,
  faHatSanta,
  // service icons
  faSnowflakes,
  faTrees,
  faOutlet,
  faAirConditioner,
  faBug,
  faFaucetDrip,
  faGalaxy,
  faHammer,
  faGaugeHigh,
  faHeadset,
  faParkingCircle,
  faShieldAlt,
  faSprinkler,
  faTape,
  faVacuum,
  faPaintbrush,
  faPersonDollyEmpty,
  faShoppingCart,
  faWindowFrame,
);
