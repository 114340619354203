import React, { useMemo } from 'react';
import {
  Channel,
  MessageInput as DefaultMessageInput,
  Window,
} from 'stream-chat-react';
import './ChatSidebar.scss';
import { ChannelList } from '../ChannelList/ChannelList';
import { ChatProvider } from '../ChatContext';
import { ThreadHeader } from '../Thread/ThreadHeader';
import { MessageInput } from '../MessageInput/MessageInput';
import { MessageList } from '../MessageList/MessageList';

import '../Chat.scss';
import { useProfile } from '../../../api/user/profile';
import { getChatUserId } from '../utils';

export interface ChatSidebarProps {
  channelId: string;
  defaultTopic?: string;
}

export const ChatSidebar: React.FC<ChatSidebarProps> = ({
  channelId,
  defaultTopic,
}) => {
  const { user_id } = useProfile();
  const chatUserId = getChatUserId(user_id);
  return (
    <ChatProvider
      channelId={channelId}
      additionalProps={useMemo(() => (defaultTopic ? {
        defaultMessageValue: `RE: ${defaultTopic} - `,
      } : {}), [defaultTopic])}
    >
      <div style={{ display: 'none' }}>
        <ChannelList chatUserId={chatUserId} customActiveChannel={channelId} />
      </div>
      <div className="str-chat-sidebar">
        <Channel Input={MessageInput} ThreadHeader={ThreadHeader}>
          <Window>
            <MessageList />
            <DefaultMessageInput />
          </Window>
        </Channel>
      </div>
    </ChatProvider>
  );
};
