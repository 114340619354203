import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChannelFilters as CF } from 'stream-chat';
import { ChatGenerics } from '../streamTypes';
import { useChannelRequiredFilters } from './useChannelRequiredFilters';
import { getChannelArchivedKey } from '../utils';
import { useProfile } from '../../../api/user/profile';

export type GsChannelFilters = CF<ChatGenerics>;

interface ChannelFilters {
  /**
   * Show only archived rooms
   */
  archivedRoomsOnly?: boolean;
}

export interface UseChannelsFilterHook<
  /** Procursys ui filters, simplified */
  F extends ChannelFilters = ChannelFilters,
> {
  filters: F;
  setFilters: (filters: F, merge?: boolean) => void;
  /** Transformed to GetStream API filters **/
  apiFilters: GsChannelFilters;
  filtersIsLoading: boolean;
  error?: Error;
}

export const useChannelFilters = (channelIdParam?: string): UseChannelsFilterHook => {
  const channelRequiredFilters = useChannelRequiredFilters();
  const profile = useProfile();
  const { channelId = channelIdParam } = useParams<{ channelId?: string }>();
  const [filters, setRawFilters] = useState<ChannelFilters>({});

  const apiFilters = useMemo((): GsChannelFilters => {
    const archivedFilterKey = getChannelArchivedKey(profile);
    const queryFilters: GsChannelFilters = { ...channelRequiredFilters };

    if (filters.archivedRoomsOnly) {
      queryFilters[archivedFilterKey] = true;
    }

    return channelId
      ? {
        ...channelRequiredFilters,
        id: channelId,
      } : queryFilters;
  }, [profile, channelRequiredFilters, filters, channelId]);

  const setFilters: UseChannelsFilterHook['setFilters'] = useCallback((newFilters, merge = true) => {
    setRawFilters((prevState) => (merge ? { ...prevState, ...newFilters } : newFilters));
  }, [setRawFilters]);

  return useMemo(() => ({
    filters,
    setFilters,
    apiFilters,
    filtersIsLoading: false,
  }), [
    filters, setFilters, apiFilters,
  ]);
};
