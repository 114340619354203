import { Attachment } from 'stream-chat';
import { AttachmentRole, ChatGenerics } from '../streamTypes';

// Any OG scrapped urls from this domain will be omitted
const BLACKLISTED_OG_DOMAIN = 'procursys.com';

export const filterMessageAttachments = (attachments: Attachment<ChatGenerics>[]) => attachments
  .filter((a) => !a.og_scrape_url?.includes(BLACKLISTED_OG_DOMAIN)
      && a.proc_type !== AttachmentRole.BundlePreview,
  );
