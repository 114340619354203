import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import clsx from 'clsx';
import {
  ChannelPreviewUIComponentProps,
  Avatar as DefaultAvatar, useChannelListContext,
} from 'stream-chat-react';
import { Channel } from 'stream-chat';
import { CloseOutlined, PushPin, UnarchiveOutlined } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import { ButtonGroup, Tooltip } from '@mui/material';
import { ChatGenerics } from '../streamTypes';
import { useProfile } from '../../../api/user/profile';
import { getChannelArchivedKey, getChannelName, getChatUserId } from '../utils';
import { colors } from '../../theme';
import { Permissions } from '../../../api/permissions/permissions';
import { SuccessSnackbar } from '../../notifications/SuccessSnackbar';
import { TooltipButton } from '../../buttons/TooltipButton';

const useStyles = makeStyles(() => createStyles({
  roomItem: {
    minHeight: 70,
    '&:hover': {
      '& $iconWrapper': {
        display: 'flex',
      },
    },
  },
  actions: {
    display: 'flex',
  },
  iconWrapper: {
    display: 'none',
    justifyContent: 'flex-end',
    flexGrow: 2,
  },
  archivedTitle: {
    textDecoration: 'line-through',
    color: colors.grey,
  },
  unreadCount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 0 2px',
  },
  unreadTitle: {
    fontWeight: 700,
  },
  badge: {
    width: 15,
    height: 15,
    fontSize: 16,
    backgroundColor: colors.orange,
    color: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginRight: 10,
  },
  pinned: {
    color: '#edb20e',
  },
  pinBadge: {
    position: 'absolute',
    top: 10,
    left: 39,
    color: '#edb20e',
    // transform: 'rotate(45deg)',
  },
}));

export const sortChannels = (chatUserId: string, channels: Channel[]) => channels
  .sort((a, b) => {
    // Get pinned_at for both channels (or null if not pinned)
    const aPinnedAt = a.state.members?.[chatUserId]?.pinned_at;
    const bPinnedAt = b.state.members?.[chatUserId]?.pinned_at;

    // If both channels are pinned, sort by pin date (newest first)
    if (aPinnedAt && bPinnedAt) {
      return new Date(bPinnedAt).getTime() - new Date(aPinnedAt).getTime();
    }

    // If only one channel is pinned, it goes first
    if (aPinnedAt) return -1;
    if (bPinnedAt) return 1;

    // If neither is pinned, sort by last message date
    const aLastMessageAt = a.state.last_message_at || new Date(0).toISOString();
    const bLastMessageAt = b.state.last_message_at || new Date(0).toISOString();

    return new Date(bLastMessageAt).getTime() - new Date(aLastMessageAt).getTime();
  });

/**
 * Component responsive for item in channels list
 * Mostly a full copy of https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelPreview/ChannelPreviewMessenger.tsx
 * with some custom stuff like archiving etc
 */
export const ChannelPreviewMessenger: React.FC<ChannelPreviewUIComponentProps<ChatGenerics>> = ({
  active,
  Avatar = DefaultAvatar,
  channel,
  displayImage,
  latestMessagePreview,
  setActiveChannel,
  unread,
  watchers,
  className: customClassName = '',
  groupChannelDisplayInfo,
}) => {
  const classes = useStyles();
  const profile = useProfile();

  const { channels, setChannels } = useChannelListContext();
  const isPinned = !!channel.state.members?.[getChatUserId(profile.user_id)]?.pinned_at;
  const [pinningInProgress, setPinningInProgress] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const handlePinUnpin = useCallback(async (e) => {
    e.stopPropagation();
    const chatUserId = getChatUserId(profile.user_id);
    setPinningInProgress(true);
    if (!isPinned) {
      await channel.pin();
      setSuccessMsg('Pinned the Channel');
    } else {
      await channel.unpin();
      setSuccessMsg('Un pinned the Channel');
    }

    const newChannels = channels.map((c) => {
      if (c.id !== channel.id) {
        return c;
      }
      if (!isPinned) {
        // eslint-disable-next-line no-param-reassign
        c.state.members[chatUserId].pinned_at = new Date().toISOString();
      } else {
        // eslint-disable-next-line no-param-reassign
        delete c.state.members[chatUserId].pinned_at;
      }
      return c;
    });
    setChannels(newChannels);

    setPinningInProgress(false);
  }, [isPinned, channel, setChannels, profile.user_id, channels]);

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);
  const isOrgOwner = useMemo(
    () => !!profile.userPermissions.find(
      (p) => p.permission_id === Permissions.ORGANIZATION_OWNER,
    ),
    [profile.userPermissions],
  );

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  const archivedByChannelKey = useMemo(() => getChannelArchivedKey(profile), [profile]);
  const isArchived = useMemo(() => !!channel.data?.[archivedByChannelKey],
    [archivedByChannelKey, channel.data],
  );

  const archiveRestoreChannel = async () => {
    await channel.updatePartial({
      set: { [archivedByChannelKey]: !isArchived },
    });
  };

  const displayTitle = getChannelName(channel, profile);
  const avatarName = displayTitle
    || channel.state.messages[channel.state.messages.length - 1]?.user?.id;

  return (
    <div className="str-chat__channel-preview-container">
      {/* <ChannelPreviewActionButtons channel={channel} />*/}
      <button
        aria-label={`Select Channel: ${displayTitle || ''}`}
        aria-selected={active}
        className={clsx(
          'str-chat__channel-preview-messenger str-chat__channel-preview',
          active && 'str-chat__channel-preview-messenger--active',
          unread && unread >= 1 && 'str-chat__channel-preview-messenger--unread',
          customClassName,
          classes.roomItem,
        )}
        data-testid="channel-preview-button"
        onClick={onSelectChannel}
        ref={channelPreviewButton}
        role="option"
        type="button"
      >
        <div className="str-chat__channel-preview-messenger--left">
          <Avatar
            className="str-chat__avatar--channel-preview"
            groupChannelDisplayInfo={groupChannelDisplayInfo}
            image={displayImage}
            name={avatarName}
          />
          {isPinned ? (
            <PushPin
              fontSize="small"
              className={classes.pinBadge}
            />
          ) : null}
        </div>
        <div className="str-chat__channel-preview-end">
          <div className="str-chat__channel-preview-end-first-row">
            <div
              className={`str-chat__channel-preview-messenger--name ${
                isArchived ? classes.archivedTitle : ''
              } ${unread !== undefined && unread > 0 ? classes.unreadTitle : ''}`}
            >
              <span>{displayTitle}</span>
            </div>
          </div>
          <div className="str-chat__channel-preview-messenger--last-message">
            {isArchived ? '' : latestMessagePreview}
          </div>
        </div>
        <div className={classes.actions}>
          {unread !== undefined && unread > 0 && (
            <div className={`${classes.iconWrapper} ${classes.unreadCount}`}>
              <Tooltip title="This room has unread messages">
                <div className={classes.badge} />
              </Tooltip>
            </div>
          )}
          <div className={classes.iconWrapper}>
            <ButtonGroup>
              <TooltipButton
                title={isPinned ? 'Pinned. Click to Unpin' : 'Click to Pin'}
                size="small"
                variant="text"
                onClick={handlePinUnpin}
                disabled={pinningInProgress}
              >
                <PushPin fontSize="small" className={isPinned ? classes.pinned : undefined} />
              </TooltipButton>
              {isOrgOwner && !unread && (
                <TooltipButton
                  title={isArchived ? 'Restore this room' : 'Archive this room (Only for your organization)'}
                  onClick={archiveRestoreChannel}
                  size="small"
                  variant="text"
                >
                  {isArchived ? <UnarchiveOutlined fontSize="small" /> : <CloseOutlined fontSize="small" />}
                </TooltipButton>
              )}
            </ButtonGroup>
          </div>
        </div>
      </button>
      <SuccessSnackbar open={!!successMsg} onClose={() => setSuccessMsg('')} autoHideDuration={5000}>
        {successMsg}
      </SuccessSnackbar>
    </div>
  );
};
