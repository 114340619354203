import L, { LatLng } from 'leaflet';
import { useEffect } from 'react';
import { FeatureCollection, GeoJsonObject } from 'geojson';
import { update as _update } from 'lodash';
import { colors } from '../../theme';
import { CLICK_MODE_CHANGED_EVENT, ClickModeSwitchEvent, LeafletClickMode } from './clickModeSwitch';

const SELECTION_STYLE = {
  color: colors.orange,
  weight: 1,
};

export function addSelectionControls(map: L.Map): L.FeatureGroup {
  const drawnItems = new L.FeatureGroup();
  map.addLayer(drawnItems);

  // Selection control
  const drawControl = new L.Control.Draw({
    edit: {
      featureGroup: drawnItems,
      edit: false,
      remove: false,
    },
    draw: {
      polyline: false,
      circlemarker: false,
      marker: false,
      rectangle: {
        shapeOptions: SELECTION_STYLE,
        metric: false,
      },
      circle: {
        shapeOptions: SELECTION_STYLE,
        metric: false,
        feet: false,
      },
    },
  });

  L.drawLocal.draw.toolbar.buttons.rectangle = 'Draw a rectangle to select a bigger area';
  L.drawLocal.draw.toolbar.buttons.circle = 'Draw a circle to select a bigger area';
  L.drawLocal.draw.toolbar.buttons.polygon = 'Draw a polygon to select a bigger area';

  // add selection control only for add/remove modes
  map.on(CLICK_MODE_CHANGED_EVENT, (e: L.LeafletEvent) => {
    if ((e as ClickModeSwitchEvent).mode === 'view') {
      map.removeControl(drawControl);
    } else {
      map.addControl(drawControl);
    }
  });

  return drawnItems;
}

export function addBundlerSelectionControls(map: L.Map): L.FeatureGroup {
  const drawnItems = new L.FeatureGroup();
  map.addLayer(drawnItems);

  // Selection control
  const drawControl = new L.Control.Draw({
    edit: {
      featureGroup: drawnItems,
      edit: false,
      remove: false,
    },
    draw: {
      polyline: false,
      circlemarker: false,
      marker: false,
      rectangle: false,
      circle: false,
    },
  });
  map.addControl(drawControl);
  L.drawLocal.draw.toolbar.buttons.polygon = 'Draw a polygon to select markers';

  return drawnItems;
}

export const useDrawingProgress = (
  map: L.Map | undefined,
  onDrawingProgressChange: (isDrawing: boolean) => void,
) => {
  useEffect(() => {
    if (map) {
      const drawStartHandler = () => {
        onDrawingProgressChange(true);
      };
      const drawStopHandler = () => {
        setTimeout(() => {
          onDrawingProgressChange(false);
        }, 1);
      };
      map.on(L.Draw.Event.DRAWSTART, drawStartHandler);
      map.on(L.Draw.Event.DRAWSTOP, drawStopHandler);

      return () => {
        map.off(L.Draw.Event.DRAWSTART, drawStartHandler);
        map.off(L.Draw.Event.DRAWSTOP, drawStopHandler);
      };
    }
    return () => {};
  }, [map, onDrawingProgressChange]);
};

export type SelectionUpdateHandlerFn = (
  activeMode: LeafletClickMode,
  isByState: boolean,
  shape: GeoJsonObject
) => void;

export const useSelectByClick = (
  map: L.Map | undefined,
  update: SelectionUpdateHandlerFn,
  isDrawingShapeInProgress: boolean,
  isLoading: boolean,
  activeMode: LeafletClickMode,
  isByState: boolean,
) => {
  useEffect(() => {
    if (map) {
      const handler = (e: L.LeafletEvent) => {
        if (activeMode === 'view') {
          // Do nothing if mode is viewing only
          return;
        }
        if (isDrawingShapeInProgress) {
          // If we're in a process of drawing shapes, ignore clicks
          return;
        }
        if (isLoading) {
          // If some requests are in progress, ignore clicks
          return;
        }
        const latlng = (e as any).latlng as LatLng;
        const json: FeatureCollection = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: [
                  L.Util.formatNum(latlng.lng, 6),
                  L.Util.formatNum(latlng.lat, 6),
                ],
              },
            },
          ],
        };
        update(activeMode, isByState, json);
      };
      map.on('click', handler);

      return () => {
        map.off('click', handler);
      };
    }
    return () => {};
  }, [isByState, isDrawingShapeInProgress, activeMode, isLoading, map, update]);
};

export const useSelectByShape = (
  map: L.Map | undefined,
  selectionLayer: L.FeatureGroup | undefined,
  update: SelectionUpdateHandlerFn,
  activeMode: LeafletClickMode,
  isByState: boolean,
) => {
  useEffect(() => {
    if (map && selectionLayer) {
      const createdHandler = (e: L.LeafletEvent) => {
        if (activeMode === 'view') {
          return;
        }

        // Set this to false to prevent an update.
        // Zero-area selections caused by mis-clicking can be prevented this way.
        let shouldUpdate = true;
        selectionLayer.addLayer(e.layer);
        const json = selectionLayer.toGeoJSON();
        if (e.layer instanceof L.Circle) {
          const radius = e.layer.getRadius();
          if (radius === 0) { // zero radius circle -> no area to select
            shouldUpdate = false;
          }
          _update(
            json,
            'features.0.properties',
            (newProps) => ({ ...newProps, radius }),
          );
        } else { // Not a circle
          const bounds = selectionLayer.getBounds();
          if (bounds.getNorth() === bounds.getSouth() || bounds.getEast() === bounds.getWest()) {
            // zero width/height bounds -> no area to select
            shouldUpdate = false;
          }
        }
        selectionLayer.clearLayers();
        if (shouldUpdate) {
          update(activeMode, isByState, json);
        }
      };
      map.on(L.Draw.Event.CREATED, createdHandler);

      return () => {
        map.off(L.Draw.Event.CREATED, createdHandler);
      };
    }
    return () => {};
  }, [selectionLayer, map, update, activeMode, isByState]);
};
