import React, {
  lazy, useMemo,
} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Paper } from '@mui/material';
import { ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Router } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createHashHistory } from 'history';
import * as Sentry from '@sentry/browser';
import { ApolloProvider } from '@apollo/react-hooks';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createClient } from 'api/client';
import { AuthPage } from 'pages/AuthPage';
import { BlankPage } from 'pages/BlankPage';
import { InvitationPage } from 'pages/InvitationPage';
import { AdminPage } from 'pages/AdminPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { ServiceProviderPage } from 'pages/ServiceProviderPage';
import { FacilityManagerPage } from 'pages/FacilityManagerPage';
import { mainTheme } from 'components/theme';
import { PATHS } from 'paths';
import { config } from 'config';
import { RoleAwareRedirect } from 'pages/RoleAwareRedirect';
import { hocLoginAs, useLoginAs } from 'pages/useLoginAs';
import { AppStateProvider } from 'pages/AppStateProvider/AppStateProvider';
import { OnBoardingMain } from './components/organization/sp/onboarding/OnBoardingMain';

import './fa-icons';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const history = createHashHistory();

// eslint-disable-next-line no-console
console.log('app version:', process.env.REACT_APP_SHORT_SHA);

// Add searchable routing/location info into Sentry
history.listen((location) => {
  Sentry.configureScope((scope) => {
    scope.setTag('location.pathname', location.pathname);
    scope.setTag('location.search', location.search);
    scope.setTag('location.hash', location.hash);
    scope.setExtra('location.state', location.state);
  });
});

// Since we're using hash-based routing, we need to prefix local URLs
export const LOCAL_URL_PREFIX = '/#';

const stripe = loadStripe(config.stripe.key);

const LoginForm = lazy(() => import('./components/auth/pages/login/LoginForm'));
const LoginStepTwoForm = lazy(() => import('./components/auth/pages/login/LoginStepTwoForm'));
const MagicLinkForm = lazy(() => import('./components/auth/pages/login/MagicLinkForm'));
const LoginPasswordForm = lazy(() => import('./components/auth/pages/login/PasswordForm'));
const ForgotPasswordForm = lazy(() => import('./components/auth/pages/login/ForgotPasswordForm'));
const LoginWithEmailLinkInstructions = lazy(() => import('./components/auth/pages/login/LoginWithEmailLinkInstructions'));
const AcceptInvitation = lazy(() => import('./components/auth/AcceptInvitation'));
const RequestAccessOrgTypeSelect = lazy(() => import('./components/auth/RequestAccessOrgTypeSelect'));
const RequestAccessServiceProviderForm = lazy(() => import('./components/auth/RequestAccessServiceProviderForm'));
const RequestAccessFacilityManagerForm = lazy(() => import('./components/auth/RequestAccessFacilityManagerForm'));
const RequestAccessSignUpForm = lazy(() => import('./components/auth/RequestAccessSignUpForm'));
const RequestAccessServiceProviderComplete = lazy(() => import('./components/auth/RequestAccessServiceProviderComplete'));
const RequestAccessFacilityManagerComplete = lazy(() => import('./components/auth/RequestAccessFacilityManagerComplete'));
const RequestAccessPending = lazy(() => import('./components/auth/RequestAccessPending'));
const RequestAccessRejected = lazy(() => import('./components/auth/RequestAccessRejected'));
const RequestAccessDeactivated = lazy(() => import('./components/auth/RequestAccessDeactivated'));
const RegisterServiceProviderForm = lazy(() => import('./components/auth/RegisterServiceProviderForm'));
const RegisterSignUpForm = lazy(() => import('./components/auth/RegisterSignUpForm'));
const ActiveBundles = lazy(() => import('./components/admin/activeBundles/ActiveBundles'));
const AccessRequests = lazy(() => import('./components/admin/accessRequests/AccessRequests'));
const UploadSites = lazy(() => import('./components/admin/sites/UploadSites/UploadSites'));
const AdminSites = lazy(() => import('./components/admin/sites/Sites'));
const Organizations = lazy(() => import('./components/admin/organizations/Organizations'));
const CreateGhostOrg = lazy(() => import('./components/admin/createGhostOrg/CreateGhostOrg'));
const OrganizationPage = lazy(() => import('./components/organization/OrganizationPage'));
const ServiceInformation = lazy(() => import('./components/organization/sp/serviceInformation/ServiceInformation'));
const UserProfilePage = lazy(() => import('./components/user/profile/UserProfilePage'));
const FmSites = lazy(() => import('./components/organization/sites/FmSites'));
const EditSite = lazy(() => import('./components/organization/sites/editSite/EditSite'));
const FmSiteDetail = lazy(() => import('./components/organization/sites/siteDetail/FmSiteDetail'));
const SpSiteDetail = lazy(() => import('./components/organization/sites/siteDetail/SpSiteDetail'));
const FmBidCenter = lazy(() => import('./components/organization/fm/bundles/BidCenter'));
const BundleDetail = lazy(() => import('./components/organization/bundle/BundleDetail'));
const InviteServiceProviders = lazy(() => import('./components/organization/fm/inviteServiceProviders/InviteServiceProviders'));
const CompareBids = lazy(() => import('./components/organization/bid/compare/CompareBids'));
const Bundler = lazy(() => import('./components/organization/fm/bundler/Bundler'));
const Screeners = lazy(() => import('./components/organization/fm/screeners/Screeners'));
const MyProviders = lazy(() => import('./components/organization/fm/myProviders/MyProviders'));
const ContractTerms = lazy(() => import('./components/organization/fm/contractTerms/ContractTerms'));
const ContractDetail = lazy(() => import('./components/organization/fm/contractTerms/contractDetail/ContractDetail'));
const UserProfilePasswordChanged = lazy(() => import('./components/user/UserProfilePasswordChangedPage'));
const SPBidPage = lazy(() => import('./components/organization/bid/SpBidPage/SPBidPage'));
const FMBidPage = lazy(() => import('./components/organization/bid/FMBidPage'));
const SPBidCenter = lazy(() => import('./components/organization/sp/BidCenter'));
const DeclineBidInvite = lazy(() => import('./components/organization/sp/DeclineBidInvite'));
const Dashboard = lazy(() => import('./components/organization/dashboard/LookerStudioDashboard'));
const Reports = lazy(() => import('./components/organization/reports/Reports'));
const ReportDetail = lazy(() => import('./components/organization/reports/reportDetail/ReportDetail'));
const Chat = lazy(() => import('./components/chat/Chat'));
const InviteGapsPage = lazy(() => import('./components/admin/inviteGaps/InviteGapsPage'));
const EnvelopesList = lazy(() => import('./components/envelope/EnvelopesList/EnvelopesList'));
const ListSpBidAllowances = lazy(() => import('./components/admin/spBidAllowances/ListSpBidAllowances'));
const AdminListBanners = lazy(() => import('./components/banners/AdminListBanners'));
const BillingPage = lazy(() => import('./components/organization/billing/BillingPage'));

const OnboardingWelcome = lazy(() => import('./components/organization/sp/onboarding/pages/WelcomePage'));
const OnBoardingProfile = lazy(
  () => import('./components/organization/sp/onboarding/pages/sub/OnBoardingProfile/OnBoardingProfile'),
);
const OnBoardingKeyContacts = lazy(
  () => import('./components/organization/sp/onboarding/pages/sub/OnBoardingKeyContacts/OnBoardingKeyContacts'),
);
const OnBoardingDiversity = lazy(
  () => import('./components/organization/sp/onboarding/pages/sub/OnBoardingDiversity'),
);
const OnBoardingDocuments = lazy(
  () => import('./components/organization/sp/onboarding/pages/sub/OnBoardingDocuments'),
);
const OnBoardingServices = lazy(
  () => import('./components/organization/sp/onboarding/pages/sub/OnBoardingServices/OnBoardingServices'),
);
const OrganizationTeam = lazy(() => import('./components/organization/team/OrganizationTeam'));

function App() {
  const { loginAs } = useLoginAs();
  const client = useMemo(() => createClient(loginAs), [loginAs]);

  return (
    <Elements stripe={stripe}>
      <ApolloProvider client={client}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <AppStateProvider>
              <CssBaseline />
              <Router history={history}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Switch>
                    <Route exact path={PATHS.home}>
                      <AuthPage title="Log In">
                        <LoginForm />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.loginStepTwo}>
                      <AuthPage title="One Click and you're in">
                        <LoginStepTwoForm />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.loginMagicLinkRequest}>
                      <AuthPage title="Request Login Link">
                        <MagicLinkForm />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.loginWithPassword}>
                      <AuthPage title="Log In with Password">
                        <LoginPasswordForm />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.forgotPassword}>
                      <AuthPage title="Forgot Password">
                        <ForgotPasswordForm />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.loginWithEmailLinkInstructions}>
                      <AuthPage title="Thank You!">
                        <LoginWithEmailLinkInstructions />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.acceptInvitation}>
                      <AuthPage title="Accept Invitation">
                        <AcceptInvitation />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.declineBundle}>
                      <BlankPage title="Decline Bundle" subtitle="">
                        <DeclineBidInvite />
                      </BlankPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.start}>
                      <AuthPage title="Request Access">
                        <RequestAccessOrgTypeSelect />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.sp}>
                      <InvitationPage>
                        <RequestAccessServiceProviderForm />
                      </InvitationPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.spSignUp}>
                      <AuthPage title="Request Access">
                        <RequestAccessSignUpForm type="sp" />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.fm}>
                      <AuthPage title="Join as a Facility Manager">
                        <RequestAccessFacilityManagerForm />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.fmSignUp}>
                      <AuthPage title="Request Access">
                        <RequestAccessSignUpForm type="fm" />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.complete.sp}>
                      <AuthPage title="Request Access">
                        <RequestAccessServiceProviderComplete />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.complete.fm}>
                      <AuthPage title="Thank You!">
                        <RequestAccessFacilityManagerComplete />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.pending}>
                      <AuthPage title="Request Access">
                        <RequestAccessPending />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.rejected}>
                      <AuthPage title="Request Access">
                        <RequestAccessRejected />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.requestAccess.deactivated}>
                      <AuthPage title="Request Access">
                        <RequestAccessDeactivated />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.register.sp}>
                      <InvitationPage preApproved>
                        <RegisterServiceProviderForm type="selfRegistration" preApproved />
                      </InvitationPage>
                    </Route>
                    {/* deprecated route */}
                    <Route exact path={PATHS.register.spSignUp}>
                      <AuthPage title="Sign Up">
                        <RegisterSignUpForm />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.register.spByInvite}>
                      <InvitationPage>
                        <RegisterServiceProviderForm type="invite" />
                      </InvitationPage>
                    </Route>
                    <Route exact path={PATHS.register.acceptGhost}>
                      <InvitationPage>
                        <RegisterServiceProviderForm type="invite" />
                      </InvitationPage>
                    </Route>
                    <Route exact path={PATHS.register.registerGhost}>
                      <InvitationPage preApproved>
                        <RegisterServiceProviderForm type="invite" preApproved />
                      </InvitationPage>
                    </Route>
                    <Route exact path={PATHS.admin.main}>
                      <Redirect to={PATHS.admin.accessRequests} />
                    </Route>
                    <Route exact path={PATHS.admin.activeBundles}>
                      <AdminPage>
                        <ActiveBundles />
                      </AdminPage>
                    </Route>
                    <Route path={PATHS.admin.accessRequests}>
                      <AdminPage>
                        <AccessRequests />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.organizations}>
                      <AdminPage>
                        <Organizations />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.uploadSites}>
                      <AdminPage>
                        <UploadSites />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.sites}>
                      <AdminPage>
                        <AdminSites />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.banners}>
                      <AdminPage>
                        <AdminListBanners />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.createGhostOrg}>
                      <AdminPage>
                        <CreateGhostOrg />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.reports}>
                      <AdminPage>
                        <Reports navType="admin" />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.reportDetail}>
                      <AdminPage>
                        <ReportDetail navType="admin" />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.spBidAllowances}>
                      <AdminPage>
                        <ListSpBidAllowances />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.admin.bundlesAvailableForSp}>
                      <AdminPage>
                        <InviteGapsPage />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.sp.main}>
                      <Redirect to={PATHS.sp.bundles} />
                    </Route>
                    <Route exact path={PATHS.sp.emptyPageForSidebar}>
                      <ServiceProviderPage />
                    </Route>
                    <Route exact path={PATHS.fm.main}>
                      <Redirect to={PATHS.fm.organizationSites} />
                    </Route>
                    <Route exact path={PATHS.fm.emptyPageForSidebar}>
                      <FacilityManagerPage />
                    </Route>
                    <Route path={PATHS.sp.organizationInfo.main}>
                      <ServiceProviderPage>
                        <OrganizationPage navType="sp" />
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.fm.organizationInfo.main}>
                      <FacilityManagerPage>
                        <OrganizationPage navType="fm" />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.reports}>
                      <FacilityManagerPage>
                        <Reports navType="fm" />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.reportDetail}>
                      <FacilityManagerPage>
                        <ReportDetail navType="fm" />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.sp.organizationService}>
                      <ServiceProviderPage>
                        <ServiceInformation />
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.sp.bid}>
                      <ServiceProviderPage>
                        <SPBidPage />
                      </ServiceProviderPage>
                    </Route>
                    <Route exact path={PATHS.sp.bundleDetail}>
                      <ServiceProviderPage>
                        <BundleDetail navType="sp" />
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.sp.bundles}>
                      <ServiceProviderPage>
                        <SPBidCenter />
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.sp.billing}>
                      <ServiceProviderPage>
                        <BillingPage />
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.sp.team}>
                      <ServiceProviderPage>
                        <Paper>
                          <OrganizationTeam navType="sp" />
                        </Paper>
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.sp.profile}>
                      <ServiceProviderPage>
                        <OnBoardingProfile mode="standalone" />
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.sp.services}>
                      <ServiceProviderPage>
                        <OnBoardingServices mode="standalone" />
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.sp.diversity}>
                      <ServiceProviderPage>
                        <OnBoardingDiversity mode="standalone" />
                      </ServiceProviderPage>
                    </Route>
                    <Route path={PATHS.sp.documents}>
                      <ServiceProviderPage>
                        <OnBoardingDocuments mode="standalone" />
                      </ServiceProviderPage>
                    </Route>
                    <Route exact path={PATHS.sp.envelopes}>
                      <ServiceProviderPage>
                        <EnvelopesList navType="sp" />
                      </ServiceProviderPage>
                    </Route>
                    <Route exact path={PATHS.admin.userProfile}>
                      <AdminPage>
                        <UserProfilePage navType="admin" />
                      </AdminPage>
                    </Route>
                    <Route exact path={PATHS.sp.userProfile}>
                      <ServiceProviderPage>
                        <UserProfilePage navType="sp" />
                      </ServiceProviderPage>
                    </Route>
                    <Route exact path={PATHS.sp.onboarding.welcome}>
                      <AuthPage title="WelcomePage | Onboarding">
                        <OnboardingWelcome />
                      </AuthPage>
                    </Route>
                    <Redirect
                      path={PATHS.sp.onboarding.main}
                      exact
                      to={PATHS.sp.onboarding.profile}
                    />
                    <Route path={PATHS.sp.onboarding.profile}>
                      <OnBoardingMain>
                        <OnBoardingProfile mode="integrated" />
                      </OnBoardingMain>
                    </Route>
                    <Route path={PATHS.sp.onboarding.key_contacts}>
                      <OnBoardingMain>
                        <OnBoardingKeyContacts mode="integrated" />
                      </OnBoardingMain>
                    </Route>
                    <Route path={PATHS.sp.onboarding.services}>
                      <OnBoardingMain>
                        <OnBoardingServices mode="integrated" />
                      </OnBoardingMain>
                    </Route>
                    <Route path={PATHS.sp.onboarding.diversity}>
                      <OnBoardingMain>
                        <OnBoardingDiversity mode="integrated" />
                      </OnBoardingMain>
                    </Route>
                    <Route path={PATHS.sp.onboarding.documents}>
                      <OnBoardingMain>
                        <OnBoardingDocuments mode="integrated" />
                      </OnBoardingMain>
                    </Route>
                    <Route exact path={PATHS.fm.userProfile}>
                      <FacilityManagerPage>
                        <UserProfilePage navType="fm" />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.admin.passwordUpdated}>
                      <AuthPage>
                        <UserProfilePasswordChanged />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.sp.passwordUpdated}>
                      <AuthPage>
                        <UserProfilePasswordChanged />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.fm.passwordUpdated}>
                      <AuthPage>
                        <UserProfilePasswordChanged />
                      </AuthPage>
                    </Route>
                    <Route exact path={PATHS.fm.dashboard}>
                      <FacilityManagerPage>
                        <Dashboard />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationSites}>
                      <FacilityManagerPage>
                        <FmSites />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationSiteCreate}>
                      <FacilityManagerPage>
                        <EditSite />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationSiteEdit}>
                      <FacilityManagerPage>
                        <EditSite />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.sp.organizationSiteDetail}>
                      <ServiceProviderPage>
                        <SpSiteDetail />
                      </ServiceProviderPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationSiteDetail}>
                      <FacilityManagerPage>
                        <FmSiteDetail />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationBundleInvite}>
                      <FacilityManagerPage>
                        <InviteServiceProviders />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationBundleBidsCompare}>
                      <FacilityManagerPage>
                        <CompareBids />
                      </FacilityManagerPage>
                    </Route>
                    <Route path={PATHS.fm.organizationBundleDetail}>
                      <FacilityManagerPage>
                        <BundleDetail navType="fm" />
                      </FacilityManagerPage>
                    </Route>
                    <Route path={PATHS.fm.organizationBundles}>
                      <FacilityManagerPage>
                        <FmBidCenter />
                      </FacilityManagerPage>
                    </Route>

                    <Route exact path={PATHS.fm.bundler}>
                      <FacilityManagerPage>
                        <Bundler />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationContractTerms}>
                      <FacilityManagerPage>
                        <ContractTerms />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationContractDetail}>
                      <FacilityManagerPage>
                        <ContractDetail />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationScreeners}>
                      <FacilityManagerPage>
                        <Screeners />
                      </FacilityManagerPage>
                    </Route>
                    <Route exact path={PATHS.fm.organizationProviders}>
                      <FacilityManagerPage>
                        <MyProviders />
                      </FacilityManagerPage>
                    </Route>
                    <Route path={PATHS.fm.bid}>
                      <FacilityManagerPage>
                        <FMBidPage />
                      </FacilityManagerPage>
                    </Route>
                    {/* Chat Routes */}
                    <Route exact path={[PATHS.sp.chat, PATHS.sp.chatChannel]}>
                      <ServiceProviderPage noTopLevelScroll>
                        <Chat />
                      </ServiceProviderPage>
                    </Route>
                    <Route exact path={[PATHS.fm.chat, PATHS.fm.chatChannel]}>
                      <FacilityManagerPage noTopLevelScroll>
                        <Chat />
                      </FacilityManagerPage>
                    </Route>
                    {/* Permalinks */}
                    <Route exact path={PATHS.permalinks.messages}>
                      <RoleAwareRedirect
                        to={{
                          fm: PATHS.fm.messages,
                          sp: PATHS.sp.messages,
                        }}
                      />
                    </Route>
                    <Route exact path={PATHS.permalinks.bid}>
                      <RoleAwareRedirect
                        to={{
                          fm: PATHS.fm.bid,
                          sp: PATHS.sp.bid,
                        }}
                      />
                    </Route>
                    <Route exact path={PATHS.permalinks.bundleDetail}>
                      <RoleAwareRedirect
                        to={{
                          fm: PATHS.fm.organizationBundleDetail,
                          sp: PATHS.sp.bundleDetail,
                        }}
                      />
                    </Route>
                    <Route exact path={PATHS.permalinks.envelopes}>
                      <RoleAwareRedirect
                        to={{
                          fm: PATHS.fm.organizationEnvelopes,
                          sp: PATHS.sp.envelopes,
                        }}
                      />
                    </Route>
                    {/** 404 route */}
                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </LocalizationProvider>
              </Router>
            </AppStateProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </Elements>
  );
}

export default hocLoginAs(history, App);
