import React from 'react';
import { Pager } from '@progress/kendo-react-data-tools';
import { createStyles, makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

import { colors } from 'components/theme';

import { UsePaginationResult } from './usePagination';

export const useStyles = makeStyles((theme) => createStyles({
  pager: (props: { bottom: boolean }) => ({
    border: 0,
    borderTop: `1px solid ${colors.lightGrey}`,
    borderBottomLeftRadius: props.bottom ? theme.shape.borderRadius : undefined,
    borderBottomRightRadius: props.bottom ? theme.shape.borderRadius : undefined,
    overflow: 'auto',
  }),
}));

export interface CustomPagerProps extends Omit<UsePaginationResult, 'clearPagination'> {
  bottom?: boolean;
  total: number;
  pageSizes: number[];
  info?: boolean;
  buttonCount?: number;
}

export const CustomPager: React.FC<CustomPagerProps> = ({
  bottom = false,
  page,
  onPageChange,
  total,
  pageSizes,
  info,
  buttonCount = 10,
}) => {
  const pagerClasses = useStyles({ bottom });
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Pager
      type="numeric"
      previousNext
      skip={page.skip ?? 0}
      take={page.take ?? 0}
      total={total}
      pageSizes={pageSizes}
      onPageChange={onPageChange}
      className={pagerClasses.pager}
      info={typeof info === 'boolean' ? info : !isMobile}
      buttonCount={buttonCount}
      responsive
    />
  );
};
