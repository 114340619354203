import React from 'react';
import {
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles } from '@mui/styles';

import { useUpsertFmStat } from './useUpsertFmStat';
import { ErrorSnackbar, useErrorSnackbar } from '../../../notifications/ErrorSnackbar';
import { FmSpLevelsEnum } from '../../../../api/fmSpStats/fmSpLevels';
import { FmSpStatPreferenceLevel } from '../../../../api/types/globalTypes';
import { ButtonLoading } from '../../../ButtonLoading';
import { TooltipIconButton } from '../../../buttons/TooltipIconButton';
import { SuccessSnackbar } from '../../../notifications/SuccessSnackbar';
import { useProfile } from '../../../../api/user/profile';
import { PreferenceButtonPrefetchProps } from './BasePreferenceButtonTypes';
import { useStatData } from './useStatData';
import kid_star from './kid_star.svg';

const useStyles = makeStyles(() => createStyles({
  icon: { opacity: 0.25 },
  active: { opacity: 1 },
  listItemIcon: { minWidth: '24px' },
}));

export interface FmPreferenceProps extends PreferenceButtonPrefetchProps {
  spOrganizationId: number;
  size?: 'small' | 'medium';
}

export const FmPreferenceButton: React.FC<FmPreferenceProps> = ({
  spOrganizationId,
  size,
  preferenceLevel: prefetchPreferenceLevel,
  levels: prefetchLevels,
}) => {
  const classes = useStyles();
  const { organization } = useProfile();

  const {
    error,
    loading,
    preference,
    levels,
  } = useStatData(
    'fm',
    organization.organization_id,
    spOrganizationId,
    { preferenceLevel: prefetchPreferenceLevel, levels: prefetchLevels },
  );
  const errorFmSpSnackbar = useErrorSnackbar(error);

  const {
    error: upsertFmSpStatError,
    loading: upsertLoading,
    upsert: upsertFmStatHandler,
    successUpsert,
    successUpsertClose,
  } = useUpsertFmStat();
  const upsertFmSpStatErrorSnackbar = useErrorSnackbar(upsertFmSpStatError);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenMenu = (event: any) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  if (!preference || !levels || loading) {
    return <ButtonLoading loading />;
  }

  const favoriteLevel = levels.find((l) => l.level_id === FmSpLevelsEnum.Favorite);
  const blacklistedLevel = levels.find((l) => l.level_id === FmSpLevelsEnum.Blacklisted);

  const favorite = preference === FmSpStatPreferenceLevel.Favorite;
  const blacklisted = preference === FmSpStatPreferenceLevel.BlackList;

  return (
    <Grid container spacing={0.5} direction="row" alignItems="center" flexWrap="nowrap">
      {/** favorite button */}
      <Grid item>
        <TooltipIconButton
          size={size}
          disabled={upsertLoading}
          title={favorite ? 'This SP is in your favorite list, press to undo' : 'Make this SP favorite'}
          className={`${classes.icon} ${favorite ? classes.active : ''}`}
          onClick={() => upsertFmStatHandler(
            spOrganizationId,
            favorite ? FmSpStatPreferenceLevel.Default : FmSpStatPreferenceLevel.Favorite,
          )}
        >
          <div
            style={{
              width: 20,
              height: 20,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${kid_star})`,
              color: favoriteLevel?.color ?? '#fff',
            }}
          />
        </TooltipIconButton>
      </Grid>
      <Grid item>
        <IconButton
          size={size}
          aria-controls="fm-preference-more-button"
          aria-haspopup="true"
          onClick={handleOpenMenu}
        >
          <FontAwesomeIcon icon="ellipsis-h" />
        </IconButton>
        <Menu
          id="fm-preference-more-button"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={async () => {
              handleCloseMenu();
              await upsertFmStatHandler(
                spOrganizationId,
                blacklisted
                  ? FmSpStatPreferenceLevel.Default
                  : FmSpStatPreferenceLevel.BlackList,
              );
            }}
          >
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <FontAwesomeIcon
                className={`${classes.icon} ${blacklisted ? classes.active : ''}`}
                color={blacklistedLevel!.color ?? '#000'}
                icon={blacklistedLevel!.icon as IconProp}
              />
            </ListItemIcon>
            {blacklisted ? 'Undo ' : ''}
            Blacklist
          </MenuItem>
        </Menu>
      </Grid>

      <ErrorSnackbar {...errorFmSpSnackbar}>
        Failed loading sp preferences
      </ErrorSnackbar>
      <ErrorSnackbar {...upsertFmSpStatErrorSnackbar}>
        Failed updating sp preferences
      </ErrorSnackbar>
      <SuccessSnackbar
        open={!!successUpsert}
        onClose={successUpsertClose}
      >
        Successfully updated the preferences
      </SuccessSnackbar>
    </Grid>
  );
};
